/** @deprecated - this is moved to libs */
export interface IScrollData {
    direction: ScrollDirection;
    percentage: number;
}

/** @deprecated - this is moved to libs */
export enum ScrollDirection {
    Up = 'up',
    Down = 'down',
}
