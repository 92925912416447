import { Injectable, Inject } from '@angular/core';
import { Observable, animationFrameScheduler } from 'rxjs';
import { debounceTime, map, startWith, throttleTime, pairwise, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { WINDOW } from '@inshared/shared/util';
import { DOCUMENT } from '@angular/common';
import { EventListenerService } from './event-listener.service';
import { IScrollData, ScrollDirection } from '@app/core/services/scroll-data';

@Injectable({
    providedIn: 'root'
})
export class IntersectionObserverService {
    private smallSize: number = 768;
    private mediumSize: number = 1024;

    // TODO: use intersection observer api - https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    public screenSizeChanged$: Observable<boolean> = this.eventListenerService.windowEvent<Event>('resize').pipe(
        debounceTime(500),
        map(() => this.document.body.offsetWidth < this.smallSize)
    );

    public isScreenSmall$: Observable<boolean> = this.screenSizeChanged$.pipe(
        startWith(this.document.body.offsetWidth < this.smallSize),
        shareReplay(1)
    );

    public isScreenMedium$: Observable<boolean> = this.screenSizeChanged$.pipe(
        startWith(this.document.body.offsetWidth <= this.mediumSize),
        map(() => this.document.body.offsetWidth <= this.mediumSize),
        shareReplay(1)
    );

    public isScreenLarge$: Observable<boolean> = this.isScreenSmall$.pipe(
        map(small => !small),
    );

    public isScrolledUp$: Observable<boolean> = this.eventListenerService.windowEvent<Event>('scroll').pipe(
        throttleTime(0, animationFrameScheduler),
        map(() => this.window.pageYOffset),
        pairwise(),
        map(([y1, y2]) => (y2 < y1 ? true : false)),
        distinctUntilChanged(),
    );

    public onScroll$: Observable<IScrollData> = this.eventListenerService.windowEvent<Event>('scroll').pipe(
        throttleTime(0, animationFrameScheduler),
        map(() => this.window.pageYOffset),
        pairwise(),
        map(([y1, y2]) => (y2 < y1 ? ScrollDirection.Up : ScrollDirection.Down)),
        map((direction) => ({
          direction,
          percentage: Math.round((this.window.pageYOffset / (this.document.body.offsetHeight - this.window.innerHeight)) * 100),
        })),
        distinctUntilChanged(),
        debounceTime(100)
      );

    public size$: Observable<string> = this.eventListenerService.windowEvent<Event>('resize').pipe(
        debounceTime(500),
        startWith(this.document.body.offsetWidth),
        map(() => this.document.body.offsetWidth < this.smallSize ? 'small' : 'large')
    );


    public constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private eventListenerService: EventListenerService,
    ) {}

    public getSmallSize(): number {
        return this.smallSize;
    }

    public setSmallSize(size: number): void {
        this.smallSize = size;
    }
}
